import React, { useEffect, useMemo, useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import DataGrid, {
  Editing,
  Paging,
  Toolbar,
  Button as GridButton,
  Column,
  Export,
  Selection,
} from 'devextreme-react/data-grid';
import Popup from 'devextreme-react/popup';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import API from '../../../services/API';
import { Voucher, BusinessUser } from '@youga/youga-interfaces';
import useAuth from '../../../hooks/useAuth';
import moment from 'moment';
interface FormState {
  periodType: 14 | 30 | 90 | 365;
  numberOfCoupons: number;
  companyId: string;
  chargeOptions: 1 | 10 | 20 | 30 | 40 | 50 | 100;
}

interface UnregisteredUserFormState {
  companyId: string;
  email: string;
}

const VoucherSection: React.FC<RouteComponentProps> = () => {
  const { token } = useAuth();
  const [vouchers, setVouchers] = useState<Voucher[]>([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [businessUsers, setBusinessUsers] = useState<BusinessUser[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>('Wszyscy');
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [showExpired, setShowExpired] = useState(false);

  const [showAddUser, setShowAddUser] = useState(false);
  const [formValues, setFormValues] = useState<FormState>({
    periodType: 30,
    numberOfCoupons: 0,
    companyId: '',
    chargeOptions: 1,
  });

  const chargesOptions = [1, 10, 20, 30, 40, 50, 100];

  const [unregisteredUserFormValues, setUnregisteredUserFormValues] =
    useState<UnregisteredUserFormState>({
      companyId: '',
      email: '',
    });

  useEffect(() => {
    const fetchVouchers = async () => {
      try {
        if (token == null) {
          throw new Error(`Token not defined.`);
        }
        const result = await API.getVoucherById(token);
        console.log({ result });
        setVouchers(result);
      } catch (err) {}
    };
    fetchVouchers();
  }, [token]);

  useEffect(() => {
    const fetchBusinessCustomers = async () => {
      try {
        if (!token) throw new Error(`Token not defined.`);
        const result = await API.getBuisnessCustomers(token);
        setBusinessUsers(result);
      } catch (err) {
        console.error('Error fetching business customers:', err);
      }
    };
    fetchBusinessCustomers();
  }, [token]);

  const handleAddButtonClick = () => {
    setIsPopupVisible(true);
  };

  const handleToolbarPreparing = (e: any) => {
    const toolbarItems = e.toolbarOptions.items;

    const addBtn = toolbarItems.find(
      (item: any) => item.name === 'addRowButton',
    );
    if (addBtn) {
      addBtn.options = {
        ...addBtn.options,
        onClick: () => {
          setIsPopupVisible(true);
        },
      };
    }
  };

  const handleSubmitForm = async () => {
    if (token == null) {
      throw new Error(`Token not defined.`);
    }
    try {
      await API.createVoucher(token, formValues);
      setIsPopupVisible(false);
    } catch (error) {
      console.error('Failed to create business codes:', error);
    }
  };

  const getCompanyNameFromID = (b2bId: string): string => {
    const foundUser = businessUsers.find((user) => user.id === b2bId);

    return foundUser ? foundUser.companyName : 'b2c';
  };

  const handleUnregisteredUserSubmit = async () => {
    // Add the logic for submitting the unregistered user data
    if (token == null) {
      throw new Error(`Token not defined.`);
    }
    try {
      setIsPopupVisible(false);
    } catch (error) {
      console.error('Failed to upload unregistered user:', error);
    }
  };

  const renderFormContent = () => {
    if (showAddUser) {
      return (
        <>
          <div>
            <span>Choose Company: </span>
            <SelectBox
              items={businessUsers.map((user) => ({
                id: user.id,
                text: user.companyName,
              }))}
              displayExpr="text"
              valueExpr="id"
              value={unregisteredUserFormValues.companyId}
              onValueChanged={(e) =>
                setUnregisteredUserFormValues((prev) => ({
                  ...prev,
                  companyId: e.value as string,
                }))
              }
            />
          </div>
          <div>
            <span>Email: </span>
            <TextBox
              value={unregisteredUserFormValues.email}
              onValueChanged={(e) =>
                setUnregisteredUserFormValues((prev) => ({
                  ...prev,
                  email: e.value as string,
                }))
              }
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <span>Coupon Length: </span>
            <SelectBox
              items={[14, 30, 90, 365]}
              value={formValues.periodType}
              onValueChanged={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  periodType: e.value as FormState['periodType'],
                }))
              }
            />
          </div>
          <div>
            <span>Number of Coupons: </span>
            <TextBox
              value={formValues.numberOfCoupons as unknown as string}
              onValueChanged={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  numberOfCoupons: parseInt(e.value) as number,
                }))
              }
            />
          </div>
          <div>
            <span>Name of Client: </span>
            <SelectBox
              items={businessUsers.map((user) => ({
                id: user.id,
                text: user.companyName,
              }))}
              displayExpr="text"
              valueExpr="id"
              value={formValues.companyId} // This will store the id now
              onValueChanged={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  companyId: e.value as string, // This will store the id now
                }))
              }
            />
          </div>
          <div>
            <span>Number of Charges: </span>
            <SelectBox
              items={chargesOptions}
              value={formValues.chargeOptions} // This will store the id now
              onValueChanged={(e) => {
                const newValue = e.value === '-' ? '-' : parseInt(e.value);
                if (
                  chargesOptions.includes(
                    newValue as FormState['chargeOptions'],
                  )
                ) {
                  setFormValues((prev) => ({
                    ...prev,
                    chargeOptions: newValue as FormState['chargeOptions'],
                  }));
                }
              }}
            />
          </div>
        </>
      );
    }
  };

  const filteredVouchers = useMemo(() => {
    return vouchers.filter((voucher) => {
      const companyFilter =
        !selectedCompany ||
        selectedCompany === 'Wszyscy' ||
        voucher?.companyId === selectedCompany;
      const periodFilter =
        !selectedPeriod || voucher.periodType === selectedPeriod;
      const expirationFilter = showExpired || voucher.chargesLeft !== 0;
      console.log({ voucher });
      return companyFilter && periodFilter && expirationFilter;
    });
  }, [vouchers, selectedCompany, selectedPeriod, showExpired]);

  const onExporting = (e: { [x: string]: any; component: any }) => {
    if (typeof window !== 'undefined') {
      const data = e.selectedRowsOnly
        ? e.component.getSelectedRowsData()
        : e.component.getDataSource().items();
      const jsonString = JSON.stringify(data, null, 2); // Pretty print the JSON

      const blob = new Blob([jsonString], {
        type: 'application/json;charset=utf-8;',
      });

      // Create a link and set the URL to the Blob
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'lista voucherow.json');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    console.log({ e });
  };


  const handleRowRemoving = async (e: { key: any }) => {
    console.log('handleRowRemoving', e);
    const { key } = e;
    console.log({ key });
    try {
      if (token == null) {
        throw new Error(`Token not defined.`);
      }
      await API.deleteVoucher(token, e.key);
    } catch (error) {
      console.error('Failed to delete the business user:', error);
    }
  };

  const onExportingUsers = (data: any) => {
    if (typeof window !== 'undefined') {
      const formatDate = data.map((data: any) => {
        return {
          ...data,
          usageDate: moment(new Date(data.usageDate), 'YYYY-MM-DD'),
        };
      });

      console.log({ formatDate });
      const jsonString = JSON.stringify(formatDate, null, 2); // Pretty print the JSON

      const blob = new Blob([jsonString], {
        type: 'application/json;charset=utf-8;',
      });

      // Create a link and set the URL to the Blob
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'lista klientow.json');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Header showButton="menu" theme="light" />
      <div>
        <Text sx={{ textAlign: 'left', marginTop: '120px' }} variant="h3">
          Vouchery
        </Text>
        <div style={{ margin: '20px 0' }}>
          <Text>Company:</Text>
          <SelectBox
            items={[
              ...businessUsers,
              { id: 'Wszyscy', companyName: 'Wszyscy' },
            ]}
            value={selectedCompany || 'Wszyscy'}
            onValueChanged={(e) => setSelectedCompany(e.value)}
            displayExpr="companyName"
            valueExpr="id"
            placeholder="Select a company"
          />
        </div>
        <div style={{ margin: '20px 0' }}>
          <Text>Coupon duration :</Text>
          <SelectBox
            items={[
              { duration: 14 },
              {
                duration: 30,
              },
              { duration: 90 },
            ]}
            value={selectedPeriod || 'All Companies'}
            onValueChanged={(e) => setSelectedPeriod(e.value)}
            displayExpr="duration"
            valueExpr="duration"
            placeholder="Select a company"
          />
        </div>
        <div style={{ margin: '20px 0' }}>
          <SelectBox
            items={[
              { showAll: true, text: 'Show expired' },
              { showAll: false, text: 'Hide expired' },
            ]}
            value={showExpired}
            onValueChanged={(e) => setShowExpired(e.value)}
            displayExpr="text"
            valueExpr="showAll"
          />
        </div>

        <div id="data-grid-demo">
          <DataGrid
            dataSource={filteredVouchers}
            keyExpr="id"
            onToolbarPreparing={handleToolbarPreparing}
            onExporting={onExporting}
            onRowRemoving={handleRowRemoving}

          >
            <Toolbar>
              <GridButton
                text="Add Business Code"
                onClick={handleAddButtonClick}
              />
              <GridButton
                text="Add Unregistered User"
                onClick={() => {
                  setShowAddUser(true);
                  setIsPopupVisible(true);
                }}
              />
            </Toolbar>
            <Paging enabled={true} />
            <Selection mode="multiple" />

            <Editing
              mode="form"
              allowUpdating={true}
              allowAdding={true}
              allowDeleting={true}
            />
            <Column
              dataField="expirationDate"
              caption="Expiration date"
              allowEditing={false}
            />
            <Column
              dataField="chargesLeft"
              caption="Charges left"
              allowEditing={false}
            />
            <Column dataField="id" caption="Voucher" allowEditing={false} />

            <Column
              dataField="periodType"
              caption="Code duration(days)"
              allowEditing={false}
            />

            <Column
              calculateCellValue={(rowData: any) => {
                if (rowData.companyId) {
                  return getCompanyNameFromID(rowData.companyId);
                }
                return 'b2c'; // Default to 'B2C' if b2b doesn't exist
              }}
              caption="B2B client"
              allowEditing={false}
            />
            <Column
              dataField="email"
              caption="Users"
              cellRender={(rowData: any) => {
                return (
                  <div>
                    <span
                      onClick={() => {
                        const dataToExport = rowData?.data?.charges?.length
                          ? rowData?.data?.charges
                          : [
                              {
                                email: rowData.data.email,
                                usageDate: new Date(
                                  rowData.data.updatedAt,
                                ).toString(),
                              },
                            ];
                        onExportingUsers(dataToExport);
                      }}
                    >
                      {' '}
                      {rowData?.data?.charges?.length ? (
                        <a> {rowData?.data?.charges?.length}</a>
                      ) : (
                        rowData.data.email
                      )}
                    </span>
                  </div>
                );
              }}
              allowEditing={false}
            />
            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>

      <Popup
        visible={isPopupVisible}
        onHiding={() => setIsPopupVisible(false)}
        width={400}
        height={300}
        title={showAddUser ? 'Add Unregistered User' : 'Add New Business Code'}
      >
        <div>
          <button onClick={() => setShowAddUser(!showAddUser)}>
            {showAddUser ? 'Switch to Code Generation' : 'Switch to Add User'}
          </button>
          {renderFormContent()}
          <div>
            <button
              onClick={
                showAddUser ? handleUnregisteredUserSubmit : handleSubmitForm
              }
            >
              Submit
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default VoucherSection;
