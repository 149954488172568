/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import Popup from 'devextreme-react/popup';
import DataGrid, { Editing, Column, Selection, Button as GridButton, Toolbar } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import API from '../../../services/API';
import { Instructor } from 'src/types/interfaces';
import useAuth from '../../../hooks/useAuth';
import 'react-datepicker/dist/react-datepicker.css';
import 'devextreme-react/text-area';
import ContentUploadModal from './ContentUploadModal'; // Adjust the import path as necessary
import { ValueChangedEvent } from 'devextreme/ui/text_box';

interface Step {
  id: string;
  time: string;
}

const HomeSection = (_props: RouteComponentProps): React.ReactElement => {
  const { token } = useAuth();
  const [courses, setCourses] = useState<Instructor[]>([]);
  const [isStepsPopupVisible, setIsStepsPopupVisible] = useState(false);
  const [currentSteps, setCurrentSteps] = useState<Step[]>([]);
  const [newInstructor, setNewInstructor] = useState<Instructor>({
    id: 'karolina',
    collabImageRatio: 6.1290322581,
    filterColor: '50,50,50',
    collabImageLightRatio: 5.5555555556,
    descriptionEng: "Pole dance started Karolina's passion for dancing and stretching. To improve her skills, she began to train stretching under Monika's demanding eye. willingness to work and good attitude.",
    headline: 'Some headline',
    description: 'Pole dance zapoczątkował w Karolinie pasję do tańca i rozciągania. By udoskonalać swoje umiejętności, zaczęła trenować rozciąganie pod wymagającym okiem Moniki. Dzięki systematyce i wytrwałości, pasja przerodziła się w miłość do rozciągania, którą Karolina przekazuje dalej podczas treningów. Ceni sobie chęć do pracy i dobre nastawienie.',
    name: 'Karolina'
  });
  const [isPoseGroupTimesPopupVisible, setIsPoseGroupTimesPopupVisible] = useState(false);
  const [currentPoseGroupTimes, setCurrentPoseGroupTimes] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [currentSessionId, setCurrentSessionId] = useState<string | null>(null);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState<string>('');
  const [selectedInstructor, setSelectedInstructor] = useState<string>('');

  // Add a function to remove a step
  const handleRemoveStep = (index: number) => {
    const filteredSteps = currentSteps.filter((_, i) => i !== index);
    setCurrentSteps(filteredSteps);
  };

  // Add a function to remove a pose group time
  const handleRemovePoseGroupTime = (pose: string) => {
    const {[pose]: _, ...remainingPoseGroupTimes} = currentPoseGroupTimes;
    setCurrentPoseGroupTimes(remainingPoseGroupTimes);
  };

  // Update handleStepsSave and handlePoseGroupTimesSave to also update the session
  const handleStepsSave = async () => {
    if (!token) {
      console.error('Token is not available.');
      return; // Exit the function if token is null
    }
    const updatedInstructor = { ...newInstructor, steps: JSON.stringify(currentSteps) };
    setNewInstructor(updatedInstructor);
    setIsStepsPopupVisible(false);
    await API.putInstructor(token, updatedInstructor);
  };

  const handlePoseGroupTimesSave = async () => {
    if (!token) {
      console.error('Token is not available.');
      return; // Exit the function if token is null
    }
    const updatedInstructor = {
      ...newInstructor,
      pose_group_times: currentPoseGroupTimes
    };
    setNewInstructor(updatedInstructor);
    setIsPoseGroupTimesPopupVisible(false);
    await API.putInstructor(token, updatedInstructor);
  };



  // Improved styling for modals
  const modalStyle = {
    padding: '20px',
    backgroundColor: '#f3f4f6',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
  };

  useEffect(() => {
    const fetchInstructors = async () => {
      try {
        if (!token) throw new Error('Token not defined.');
        const result = await API.getInstructors(token);
        setCourses(result);
      } catch (err) {
        console.error('Error fetching business users:', err);
      }
    };
    fetchInstructors();
  }, [token]);

  const handleRowInserting = async (e: { data: any }) => {
    try {
      if (!token) throw new Error('Token not defined.');
      const newInstructor = {
        id: e.data.id || 'karolina',
        collabImageRatio: e.data.collabImageRatio || 6.1290322581,
        filterColor: e.data.filterColor || '50,50,50',
        collabImageLightRatio: e.data.collabImageLightRatio || 5.5555555556,
        descriptionEng: e.data.descriptionEng || "Pole dance started Karolina's passion for dancing and stretching. To improve her skills, she began to train stretching under Monika's demanding eye. willingness to work and good attitude.",
        headline: e.data.headline || 'Some headline',
        description: e.data.description || 'Pole dance zapoczątkował w Karolinie pasję do tańca i rozciągania. By udoskonalać swoje umiejętności, zaczęła trenować rozciąganie pod wymagającym okiem Moniki. Dzięki systematyce i wytrwałości, pasja przerodziła się w miłość do rozciągania, którą Karolina przekazuje dalej podczas treningów. Ceni sobie chęć do pracy i dobre nastawienie.',
        name: e.data.name || 'Karolina'
      };
      await API.putInstructor(token, newInstructor);
    } catch (error) {
      console.error('Failed to insert the instructor:', error);
    }
  };

  const handleVideoProcessing = async () => {
    try {
      if (!token) throw new Error('Token not defined.');
      const result = await API.processVideo(token);
      console.log('result', result);
    } catch (error) {
      console.error('Failed to process videos:', error);
    }
  };

  const handleAcl = async (data: any) => {
    try {
      if (!token) throw new Error('Token not defined.');
      const result = await API.handleAcl(token, data.data.id);
      console.log('result', result);
    } catch (error) {
      console.error('Failed to change acl:', error);
    }
  };

  const handleRowUpdating = async (e: { oldData: any; newData: any }) => {
    try {
      if (!token) throw new Error('Token not defined.');
      const updatedData = { ...e.oldData, ...e.newData, id: e.oldData.id || e.newData.id };
      await API.putInstructor(token, updatedData);
    } catch (error) {
      console.error('Failed to update the instructor:', error);
    }
  };

  const handleRowDeleting = async (e: { data: any }) => {
    try {
      if (!token) throw new Error('Token not defined.');
      await API.putInstructor(token, { id: e.data.id, delete: true } as Partial<Instructor>);
    } catch (error) {
      console.error('Failed to delete the instructor:', error);
    }
  };

  const handleStepsClick = (steps: string, sessionId: string) => {
    const instructor = courses.find((course: Instructor) => course.id === sessionId);
    if (instructor) {
      setNewInstructor(instructor);
      setCurrentSteps(JSON.parse(steps));
      setCurrentSessionId(sessionId);
      setIsStepsPopupVisible(true);
    }
  };


  const handleOpenUploadModal = (sessionId: string, instructor: string) => {
    setSelectedSessionId(sessionId);
    setSelectedInstructor(instructor);
    setIsUploadModalVisible(true);
  };



  console.log({ courses })

  return (
    <>
      <Header showButton="menu" theme="light" showConnectButton />
      <div sx={{ padding: '15px', maxWidth: '915px', margin: 'auto' }}>
        <Text sx={{ textAlign: 'left', marginTop: '120px' }} variant="h3">
          Instructors
        </Text>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          <Button text="Video processing" style={{ marginRight: '10px' }} onClick={handleVideoProcessing} />
        </div>
        <div id="data-grid-demo">
          <DataGrid
            dataSource={courses}
            keyExpr="id"
            showBorders
            onRowInserting={handleRowInserting}
            onRowUpdating={handleRowUpdating}
            onRowRemoving={handleRowDeleting}
          >
            <Selection mode="multiple" />
            <Editing mode="form" allowUpdating={true} allowAdding={true} allowDeleting />
            <Column dataField="id" caption="ID" />
            <Column dataField="collabImageRatio" caption="collabImageRatio" visible={false} />
            <Column dataField="filterColor" caption="filterColor" visible={false} />
            <Column dataField="collabImageLightRatio" caption="collabImageLightRatio" visible={false} />
            <Column dataField="descriptionEng" caption="Description (English)" visible={false} />
            <Column dataField="description" caption="descriptionEng" visible={false} />
            <Column dataField="titleEng" caption="TitleEng" visible={false} />
            <Column dataField="name" caption="Camera Angles" visible={false} />
            <Column
            caption="Upload Content"
            cellRender={(data: any) => (
              <Button
                text="Upload Content"
                onClick={() => handleOpenUploadModal(data.data.id, data.data.instructor)}
              />
            )}
          />
            <Column
              cellRender={(data: any) => <a onClick={() => handleAcl(data)}>ACL</a>}
              caption="Action"
            />

          </DataGrid>
        </div>
      </div>
      <Popup
        visible={isStepsPopupVisible}
        onHiding={() => setIsStepsPopupVisible(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Edit Steps"
        width={300}
        height={400}
        style={modalStyle}
      >
        <div>
          {currentSteps.map((step: Step, index: number) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <TextBox
                value={step.id}
                onValueChanged={(e: ValueChangedEvent) => {
                  const newSteps = [...currentSteps];
                  newSteps[index].id = e.value;
                  setCurrentSteps(newSteps);
                }}
                placeholder="Step ID"
              />
              <TextBox
                value={step.time}
                onValueChanged={(e: ValueChangedEvent) => {
                  const newSteps = [...currentSteps];
                  newSteps[index].time = e.value;
                  setCurrentSteps(newSteps);
                }}
                placeholder="Time"
              />
              <Button text="Remove" onClick={() => handleRemoveStep(index)} />
            </div>
          ))}
          <Button
            text="Add Step"
            onClick={() => setCurrentSteps([...currentSteps, { id: '', time: '' }])}
          />
          <Button
            text="Save"
            onClick={handleStepsSave}
            style={{ marginTop: '10px' }}
          />
        </div>
      </Popup>
      <Popup
        visible={isPoseGroupTimesPopupVisible}
        onHiding={() => setIsPoseGroupTimesPopupVisible(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Edit Pose Group Times"
        width={300}
        height={400}
        style={modalStyle}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {Object.entries(currentPoseGroupTimes).map(([pose, time], index) => (
            <div key={index} style={{ marginBottom: '10px', width: '50%' }}>
              <TextBox
                value={pose}
                onValueChanged={(e: ValueChangedEvent) => {
                  const newPoseGroupTimes = { ...currentPoseGroupTimes };
                  const newTime = newPoseGroupTimes[pose];
                  delete newPoseGroupTimes[pose];
                  newPoseGroupTimes[e.value] = newTime;
                  setCurrentPoseGroupTimes(newPoseGroupTimes);
                }}
                placeholder="Pose"
              />
              <TextBox
                value={time}
                onValueChanged={(e: ValueChangedEvent) => {
                  const newPoseGroupTimes = { ...currentPoseGroupTimes };
                  newPoseGroupTimes[pose] = e.value;
                  setCurrentPoseGroupTimes(newPoseGroupTimes);
                }}
                placeholder="Time"
              />
              <Button text="Remove" onClick={() => handleRemovePoseGroupTime(pose)} />
            </div>
          ))}
          <Button
            text="Add Pose Group Time"
            onClick={() => setCurrentPoseGroupTimes({ ...currentPoseGroupTimes, '': '' })}
          />
          <Button
            text="Save"
            onClick={handlePoseGroupTimesSave}
            style={{ marginTop: '10px' }}
          />
        </div>
      </Popup>
      <Popup
        visible={isUploadModalVisible}
        onHiding={() => setIsUploadModalVisible(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Upload Content"
        width={500}
        height={400}
      >
        <ContentUploadModal sessionId={selectedSessionId} instructor={selectedInstructor} token={token} />
      </Popup>
      <style>{`
        .ReactVirtualized__Table__headerColumn {
          font-size: 14px;
          font-weight: 100;
          font-family: Lato;
          text-transform: capitalize;
          margin-right: 0px;
        }
      `}</style>
    </>
  );
};

export default HomeSection;
