import React, { FC, useState } from 'react';
import axios from 'axios';
import API from '../../../services/API';
import styled from 'styled-components';

interface ContentUploadModalProps {
  sessionId: string;
  instructor: string;
  token: string;
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const FileInput = styled.input`
  margin-bottom: 20px;
`;

const ContentUploadModal: FC<ContentUploadModalProps> = ({ sessionId, instructor, token }) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files?.[0] ?? null);
  };

  const uploadFile = async (fileName: string, drop: boolean = false) => {
    if (drop) {
      const response: any = await API.getUploadUrl(token, fileName, drop);
      return;
    }
    if (!file) return;

    const path = `videos/${instructor}/${sessionId}/${fileName}`;
    try {
      const response: any = await API.getUploadUrl(token, path);
      const { url } = response;
      const uploadResponse = await axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      console.log({ uploadResponse });
      alert('File uploaded successfully');
    } catch (error) {
      console.error('Upload failed:', error);
      alert('Upload failed');
    }
  };

  return (
    <ModalContainer>
      <h2>Upload Content</h2>
      <FileInput type="file" onChange={handleFileChange} />
      <ButtonContainer>
        <Button onClick={() => uploadFile(`${sessionId}-thumbnail-small.jpg`)}>Upload Thumbnail Small</Button>
        <Button onClick={() => uploadFile(`${sessionId}-thumbnail-small@2x.jpg`)}>Upload Thumbnail Small @2x</Button>
        <Button onClick={() => uploadFile(`${sessionId}-header.jpg`)}>Upload Header Image</Button>
        <Button onClick={() => uploadFile(`${sessionId}-video-hq.mp4`)}>Upload Video HQ</Button>
        <Button onClick={() => uploadFile(`${sessionId}-video-eng-hq.mp4`)}>Upload English Video</Button>
        <Button onClick={() => uploadFile(`${sessionId}-video-hq.mp4`, true)}>Drop Video HQ</Button>
        <Button onClick={() => uploadFile(`${sessionId}-video-eng-hq.mp4`, true)}>Drop English Video</Button>
      </ButtonContainer>
    </ModalContainer>
  );
};

export default ContentUploadModal;