/* eslint-disable no-void */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect } from 'react';

import { useUser } from '@youga/youga-client-api';

const isSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

const useSubscribePushManager = (): void => {
  const { data: user } = useUser();
  const isLoaded = !!user;

  /**
   * If a user already granted Notification permission,
   * we want to either get the current subscription or subscribe again to the push-manager
   *
   * This way, we always ensure that the client has an active subscription to the push-manager,
   * even if my old subscription is expired (in this case, we would just re-subscribe)
   *
   * This effect depends on the loaded-state of the homeContents API to only trigger the subscription fetching once we loaded the API data
   */
  useEffect(() => {
    if (!isSupported()) {
      return;
    }

    if (Notification.permission !== 'granted') {
      return;
    }

    if (!isLoaded) {
      return;
    }

    void navigator.serviceWorker.ready
      .then(
        (registration) =>
          registration.active || registration.waiting || undefined,
      )
      .then((sender) => sender?.postMessage({ type: 'forceSubscription' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);
};

export default useSubscribePushManager;
