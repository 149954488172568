import { useCallback, useState } from 'react';
import type { TrackingData, TrackingEventName } from '@youga/youga-interfaces';
import useAuth from './useAuth';
import API from '../services/API';

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, Analytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBStNtP7DxW_i8CjXmupiy11x8cS6lpUw8',
  authDomain: 'stretchme-app.firebaseapp.com',
  projectId: 'stretchme-app',
  storageBucket: 'stretchme-app.appspot.com',
  messagingSenderId: '927790468189',
  appId: '1:927790468189:web:0865e816f08d56c86651b4',
  measurementId: 'G-700F9DM2MS',
};

// Initialize Firebase
let analytics: null | Analytics = null;
if (typeof window !== 'undefined') {
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
}

interface UserWithAttributes {
  username?: string | null;
  attributes: { sub: string; email: string; username?: string | null };
}

function useTracking() {
  const auth = useAuth();
  const [transactionId, setTransactionId] = useState<string>('');

  const track = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (
      eventName: TrackingEventName,
      payload?: Record<string, unknown>,
      userData?: UserWithAttributes | null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<void> => {
      if (auth.status !== 'authenticated') {
        return;
      }

      const user = userData || auth.user;

      const userId = user?.attributes.sub;
      const email = user?.attributes.email;
      // TODO: check where username is located
      const username =
        userData?.username ||
        userData?.attributes.username ||
        auth.user.username;

      const trackingData: TrackingData = {
        eventName,
        payload: payload ?? {},
        userAgent:
          typeof navigator !== 'undefined'
            ? navigator.userAgent || undefined
            : undefined,
        userId,
        email: email as string,
        username,
      };
      if (analytics) {
        logEvent(analytics, eventName, trackingData);
      }

      await API.postTrackingData(trackingData);
    },
    [auth.status, auth.user],
  );

  return {
    track,
    transactionId,
    setTransactionId,
  };
}

export default useTracking;
