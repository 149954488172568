import { useTranslation } from 'react-i18next';
import AppSection from '../components/03_organisms/AppSection/AppSection';
import Page from '../components/04_templates/Page';
import '../i18n/i18n';

function AppPage() {
  const { t } = useTranslation();
  return (
    <Page title={t('APP_PAGE_TITLE')} authRequired={true}>
      <AppSection />
    </Page>
  );
}

export default AppPage;
