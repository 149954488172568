/** @jsxImportSource theme-ui */
import React, { useEffect, useState, useRef } from 'react';
import type { RouteComponentProps } from '@reach/router';
import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import Popup from 'devextreme-react/popup';
import DataGrid, { Editing, Column, Selection, Button as GridButton, Toolbar } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import API from '../../../services/API';
import { Course } from '@youga/youga-interfaces';
import useAuth from '../../../hooks/useAuth';
import ContentUploadModal from './ContentUploadModal'; // Adjust the import path as necessary

const HomeSection = (_props: RouteComponentProps): React.ReactElement => {
  const { token } = useAuth();
  const [courses, setCourses] = useState<Course[]>([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [newCourse, setNewCourse] = useState<Partial<Course>>({
    id: '',
    sessions: [],
    styleTags: [],
    subtitleEng: ''
  });
  const [isSessionsPopupVisible, setIsSessionsPopupVisible] = useState(false);
  const [currentSessions, setCurrentSessions] = useState<string[]>([]);

  const prevNewCourseRef = useRef(newCourse);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        if (!token) throw new Error('Token not defined.');
        const result = await API.getCourses(token);
        setCourses(result);
      } catch (err) {
        console.error('Error fetching courses:', err);
      }
    };
    fetchCourses();
  }, [token]);

  useEffect(() => {
    if (prevNewCourseRef.current.id !== newCourse.id) {
      console.log({ newCourse: newCourse.id });
      prevNewCourseRef.current = newCourse;
    }
  }, [newCourse]);

  const handleAddCourse = async () => {
    try {
      if (!token) throw new Error('Token not defined.');
      await API.createCourse(token, newCourse as any);
      setCourses([...courses, newCourse as any]);
      setIsPopupVisible(false);
      setNewCourse({
        id: '',
        sessions: [],
        styleTags: [],
        subtitleEng: ''
      });
    } catch (error) {
      console.error('Failed to add the course:', error);
    }
  };

  const handleRowUpdating = async (e: { oldData: any; newData: any }) => {
    try {
      if (!token) throw new Error('Token not defined.');
      const updatedCourse = { ...e.oldData, ...e.newData };
      await API.createCourse(token, updatedCourse);
    } catch (error) {
      console.error('Failed to update the course:', error);
    }
  };

  const handleRowDeleting = async (e: { data: any }) => {
    try {
      if (!token) throw new Error('Token not defined.');
      await API.createCourse(token, { id: e.data.id, delete: true });
      setCourses(courses.filter(course => course.id !== e.data.id));
    } catch (error) {
      console.error('Failed to delete the course:', error);
    }
  };

  const handleToolbarPreparing = (e: any) => {
    const addBtn = e.toolbarOptions.items.find((item: any) => item.name === 'addRowButton');
    if (addBtn) {
      addBtn.options = { ...addBtn.options, onClick: () => setIsPopupVisible(true) };
    }
  };

  const handleEditSessions = (courseId: string) => {
    console.log('courseId', courseId);
    try {
      const course = courses.find(course => course.id === courseId);
      if (!course) throw new Error('Course not found.');
      console.log({ course })
      setNewCourse(course);
      setCurrentSessions(course.sessions ?? []);
      setIsSessionsPopupVisible(true);
    } catch (error) {
      console.error('Failed to fetch course data:', error);
    }
  };

  const handleAddSession = () => {
    setCurrentSessions((prevSessions) => [...prevSessions, '']);
  };

  const handleSessionChange = (index: number, value: string) => {
    const updatedSessions = [...currentSessions];
    updatedSessions[index] = value;
    setCurrentSessions(updatedSessions);
  };

  const handleRemoveSession = (index: number) => {
    const updatedSessions = currentSessions.filter((_, i) => i !== index);
    setCurrentSessions(updatedSessions);
  };

  const handleSaveSessions = async () => {
    try {
      console.log({ newCourse })
      if (!token) throw new Error('Token not defined.');
      const updatedCourse = { ...newCourse, sessions: currentSessions };
      await API.createCourse(token, updatedCourse as Course); // Ensure you are using the correct API method
      setCourses(courses.map(course => course.id === updatedCourse.id ? updatedCourse : course));
      setIsSessionsPopupVisible(false);
    } catch (error) {
      console.error('Failed to save sessions:', error);
    }
  };

  const handleOpenUploadModal = (courseId: string) => {
    setSelectedCourseId(courseId);
    setIsUploadModalVisible(true);
  };

  return (
    <>
      <Header showButton="menu" theme="light" />
      <div sx={{ padding: '35px', maxWidth: '915px', margin: 'auto' }}>
        <Text type="h1">Courses</Text>
        <DataGrid
          dataSource={courses}
          keyExpr="id"
          showBorders={true}
          onRowUpdating={handleRowUpdating}
          onRowRemoving={handleRowDeleting}
          onToolbarPreparing={handleToolbarPreparing}
        >
          <Editing mode="popup" allowUpdating={true} allowAdding={true} allowDeleting={true} />
          <Column dataField="title" caption="Title" visible={false} />
          <Column dataField="id" caption="ID" />
          <Column dataField="subtitle" caption="Subtitle" visible={false} />
          <Column dataField="description" caption="Description" visible={false} />
          <Column dataField="instructor" caption="Instructor" visible={false} />
          <Column dataField="introVideoDuration" caption="Intro Video Duration" visible={false} />
          <Column dataField="props" caption="Props" visible={false} />
          <Column dataField="descriptionEng" caption="Description (Eng)" visible={false} />
          <Column dataField="introTitle" caption="Intro Title" visible={false} />
          <Column dataField="introVideoUrlEng" caption="Intro Video URL (Eng)" visible={false} />
          <Column dataField="propsEng" caption="Props (Eng)" visible={false} />
          <Column dataField="sessions" caption="Sessions" visible={false} />
          <Column dataField="styleTags" caption="Style Tags" visible={false} />
          <Column dataField="subtitleEng" caption="Subtitle (Eng)" visible={false} />
          <Column dataField="teaser" caption="Teaser" visible={false} />
          <Column dataField="titleEng" caption="Title (Eng)" visible={false} />
          <Column dataField="thumbnail" caption="Thumbnail" visible={false} />
          <Column dataField="thumbnailHq" caption="High Quality Thumbnail" visible={false} />
          <Column dataField="posterImage" caption="Poster Image" visible={false} />
          <Column dataField="headerImage" caption="Header Image" visible={false} />
          <Column dataField="introVideoUrl" caption="Intro Video URL" visible={false} />
          <Column dataField="introVideoMp4Url" caption="Intro Video MP4 URL" visible={false} />
          <Column dataField="introThumbnail" caption="Intro Thumbnail" visible={false} />
          <Column dataField="introThumbnailHq" caption="High Quality Intro Thumbnail" visible={false} />
          <Column
            caption="Sessions"
            cellRender={(data) => (
              <Button
                text="Edit Sessions"
                onClick={() => handleEditSessions(data.data.id)}
              />
            )}
          />
          <Column
            caption="Upload Content"
            cellRender={(data) => (
              <Button
                text="Upload Content"
                onClick={() => handleOpenUploadModal(data.data.id)}
              />
            )}
          />
          <Column type="buttons">
            <GridButton name="edit" />
            <GridButton name="delete" />
          </Column>
          <Toolbar>
            <GridButton name="addRowButton" />
          </Toolbar>
        </DataGrid>
      </div>

      <Popup
        visible={isPopupVisible}
        onHiding={() => setIsPopupVisible(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Add Course"
        width={500}
        height={300}
      >
        <div style={{ padding: '20px' }}>
          <TextBox
            value={newCourse.id}
            onValueChanged={(e) => setNewCourse({ ...newCourse, id: e.value })}
            placeholder="ID"
          />
          <Button
            text="Save"
            onClick={handleAddCourse}
            style={{ marginTop: '10px' }}
          />
        </div>
      </Popup>

      <Popup
        visible={isSessionsPopupVisible}
        onHiding={() => setIsSessionsPopupVisible(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Edit Sessions"
        width={500}
        height={400}
      >
        <div style={{ padding: '20px' }}>
          {(currentSessions ?? []).map((session, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <TextBox
                value={session}
                onValueChanged={(e) => handleSessionChange(index, e.value)}
                placeholder={`Session ${index + 1}`}
                style={{ marginRight: '10px', flex: 1 }}
              />
              <Button text="Delete" onClick={() => handleRemoveSession(index)} />
            </div>
          ))}
          <Button text="Add Session" onClick={handleAddSession} style={{ marginRight: '10px' }} />
          <Button text="Save" onClick={handleSaveSessions} style={{ backgroundColor: '#007bff', color: '#fff' }} />
        </div>
      </Popup>

      <Popup
        visible={isUploadModalVisible}
        onHiding={() => setIsUploadModalVisible(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Upload Content"
        width={500}
        height={400}
      >
        <ContentUploadModal courseId={selectedCourseId} token={token} />
      </Popup>

      <style>{`
        .ReactVirtualized__Table__headerColumn {
          font-size: 14px;
          font-weight: 100;
          font-family: Lato;
          text-transform: capitalize;
          margin-right: 0px;
        }
        .dx-button {
          margin: 5px;
        }
      `}</style>
    </>
  );
};
export default HomeSection;
