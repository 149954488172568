/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import Popup from 'devextreme-react/popup';
import DataGrid, { Editing, Column, Selection, Button as GridButton, Toolbar } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import API from '../../../services/API';
import { BusinessUser, Course, Session, VideoSession } from '@youga/youga-interfaces';
import useAuth from '../../../hooks/useAuth';
import 'react-datepicker/dist/react-datepicker.css';
import 'devextreme-react/text-area';
import ContentUploadModal from './ContentUploadModal'; // Adjust the import path as necessary
import { ValueChangedEvent } from 'devextreme/ui/text_box';

interface Step {
  id: string;
  time: string;
}

const HomeSection = (_props: RouteComponentProps): React.ReactElement => {
  const { token } = useAuth();
  const [courses, setCourses] = useState<Course[]>([]);
  const [isStepsPopupVisible, setIsStepsPopupVisible] = useState(false);
  const [currentSteps, setCurrentSteps] = useState<Step[]>([]);
  const [newSession, setNewSession] = useState<VideoSession>({
    id: '',
    cameraAngles: '',
    description: '',
    descriptionEng: '',
    duration: '',
    highlightPoseName: '',
    instructor: '',
    level: 1,
    pose_group_times: {},
    props: '',
    propsEng: '',
    steps: '[]',
    subtitle: '',
    tags: '',
    title: '',
    titleEng: ''
  });
  const [isPoseGroupTimesPopupVisible, setIsPoseGroupTimesPopupVisible] = useState(false);
  const [currentPoseGroupTimes, setCurrentPoseGroupTimes] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [currentSessionId, setCurrentSessionId] = useState<string | null>(null);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState<string>('');
  const [selectedInstructor, setSelectedInstructor] = useState<string>('');

  // Add a function to remove a step
  const handleRemoveStep = (index: number) => {
    const filteredSteps = currentSteps.filter((_, i) => i !== index);
    setCurrentSteps(filteredSteps);
  };

  // Add a function to remove a pose group time
  const handleRemovePoseGroupTime = (pose: string) => {
    const {[pose]: _, ...remainingPoseGroupTimes} = currentPoseGroupTimes;
    setCurrentPoseGroupTimes(remainingPoseGroupTimes);
  };

  // Update handleStepsSave and handlePoseGroupTimesSave to also update the session
  const handleStepsSave = async () => {
    const updatedSession = { ...newSession, steps: JSON.stringify(currentSteps) };
    setNewSession(updatedSession);
    setIsStepsPopupVisible(false);
    await API.createSession(token, updatedSession);
  };

  const handlePoseGroupTimesSave = async () => {
    if (!token) {
      console.error('Token is not available.');
      return; // Exit the function if token is null
    }
    const updatedSession = {
      ...newSession,
      pose_group_times: currentPoseGroupTimes
    };
    setNewSession(updatedSession);
    setIsPoseGroupTimesPopupVisible(false);
    await API.createSession(token, updatedSession as any as Session);
  };

  // Placeholder for the third option with buttons
  const handleThirdOption = () => {
    console.log("Third option functionality will be discussed later.");
  };

  // Improved styling for modals
  const modalStyle = {
    padding: '20px',
    backgroundColor: '#f3f4f6',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        if (!token) throw new Error('Token not defined.');
        const result = await API.getSessions(token);
        setCourses(result);
      } catch (err) {
        console.error('Error fetching business users:', err);
      }
    };
    fetchCourses();
  }, [token]);

  const handleRowInserting = async (e: { data: any }) => {
    try {
      if (!token) throw new Error('Token not defined.');
      const newSession = {
        id: e.data.id || '',
        cameraAngles: e.data.cameraAngles || '',
        description: e.data.description || '',
        descriptionEng: e.data.descriptionEng || '',
        duration: e.data.duration || '',
        highlightPoseName: e.data.highlightPoseName || '',
        instructor: e.data.instructor || '',
        level: e.data.level || 1,
        pose_group_times: {
        },
        props: e.data.props || '',
        propsEng: e.data.propsEng || '',
        steps: e.data.steps || '[]',
        subtitle: e.data.subtitle || '',
        tags: e.data.tags || '',
        title: e.data.title || '',
        titleEng: e.data.titleEng || ''
      };
      await API.createSession(token, newSession);
    } catch (error) {
      console.error('Failed to insert the session:', error);
    }
  };

  const handleVideoProcessing = async () => {
    try {
      if (!token) throw new Error('Token not defined.');
      const result = await API.processVideo(token);
      console.log('result', result);
    } catch (error) {
      console.error('Failed to process videos:', error);
    }
  };

  const handleAcl = async (data: any) => {
    try {
      if (!token) throw new Error('Token not defined.');
      const result = await API.handleAcl(token, data.data.id);
      console.log('result', result);
    } catch (error) {
      console.error('Failed to change acl:', error);
    }
  };

  const handleRowUpdating = async (e: { oldData: any; newData: any }) => {
    try {
      if (!token) throw new Error('Token not defined.');
      const updatedData = { ...e.oldData, ...e.newData, id: e.oldData.id || e.newData.id };
      await API.createSession(token, updatedData);
    } catch (error) {
      console.error('Failed to update the session:', error);
    }
  };

  const handleRowDeleting = async (e: { data: any }) => {
    try {
      if (!token) throw new Error('Token not defined.');
      await API.createSession(token, { id: e.data.id, delete: true });
    } catch (error) {
      console.error('Failed to delete the session:', error);
    }
  };

  const handleStepsClick = (steps: string, sessionId: string) => {
    const session = courses.find((course: Course) => course.id === sessionId);
    if (session) {
      setNewSession(session);
      setCurrentSteps(JSON.parse(steps));
      setCurrentSessionId(sessionId);
      setIsStepsPopupVisible(true);
    }
  };

  const handlePoseGroupTimesClick = (poseGroupTimes: any, sessionId: string) => {
    const session = courses.find((course: Course) => course.id === sessionId);
    if (session) {
      setNewSession(session);
      setCurrentPoseGroupTimes(poseGroupTimes);
      setCurrentSessionId(sessionId);
      setIsPoseGroupTimesPopupVisible(true);
    }
  };

  const handleOpenUploadModal = (sessionId: string, instructor: string) => {
    setSelectedSessionId(sessionId);
    setSelectedInstructor(instructor);
    setIsUploadModalVisible(true);
  };

  function renderStepsCell(data: any) {
    return <a onClick={() => handleStepsClick(data.value, data.data.id)}>Edit Steps</a>;
  }

  function renderPoseGroupTimesCell(data: any) {
    return <a onClick={() => handlePoseGroupTimesClick(data.value, data.data.id)}>Edit Pose Group Times</a>;
  }

  console.log({ courses })

  return (
    <>
      <Header showButton="menu" theme="light" showConnectButton />
      <div sx={{ padding: '15px', maxWidth: '915px', margin: 'auto' }}>
        <Text sx={{ textAlign: 'left', marginTop: '120px' }} variant="h3">
          Sesje
        </Text>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          <Button text="Video processing" style={{ marginRight: '10px' }} onClick={handleVideoProcessing} />
        </div>
        <div id="data-grid-demo">
          <DataGrid
            dataSource={courses}
            keyExpr="id"
            showBorders
            onRowInserting={handleRowInserting}
            onRowUpdating={handleRowUpdating}
            onRowRemoving={handleRowDeleting}
          >
            <Selection mode="multiple" />
            <Editing mode="form" allowUpdating={true} allowAdding={true} allowDeleting />
            <Column dataField="id" caption="ID" />
            <Column dataField="title" caption="Title" visible={false} />
            <Column dataField="subtitle" caption="Subtitle" visible={false} />
            <Column dataField="description" caption="Description" visible={false} />
            <Column dataField="descriptionEng" caption="Description (English)" visible={false} />
            <Column dataField="instructor" caption="Instructor" visible={false} />
            <Column dataField="titleEng" caption="TitleEng" visible={false} />
            <Column dataField="highlightPoseName" caption="Highlight Pose Name" visible={false} />
            <Column dataField="cameraAngles" caption="Camera Angles" visible={false} />
            <Column dataField="pose_group_times" caption="Pose Group Times" cellRender={renderPoseGroupTimesCell} />
            <Column dataField="props" caption="Props" visible={false} />
            <Column dataField="propsEng" caption="Props (English)" visible={false} />
            <Column dataField="steps" caption="Steps" cellRender={renderStepsCell} />
            <Column
            caption="Upload Content"
            cellRender={(data: any) => (
              <Button
                text="Upload Content"
                onClick={() => handleOpenUploadModal(data.data.id, data.data.instructor)}
              />
            )}
          />
            <Column
              cellRender={(data: any) => <a onClick={() => handleAcl(data)}>ACL</a>}
              caption="Action"
            />
            <Column dataField="duration" caption="Duration" visible={false} />
            <Column dataField="introTitle" caption="Intro Title" visible={false} />
            <Column dataField="Notes" visible={false} />
          </DataGrid>
        </div>
      </div>
      <Popup
        visible={isStepsPopupVisible}
        onHiding={() => setIsStepsPopupVisible(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Edit Steps"
        width={300}
        height={400}
        style={modalStyle}
      >
        <div>
          {currentSteps.map((step: Step, index: number) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <TextBox
                value={step.id}
                onValueChanged={(e: ValueChangedEvent) => {
                  const newSteps = [...currentSteps];
                  newSteps[index].id = e.value;
                  setCurrentSteps(newSteps);
                }}
                placeholder="Step ID"
              />
              <TextBox
                value={step.time}
                onValueChanged={(e: ValueChangedEvent) => {
                  const newSteps = [...currentSteps];
                  newSteps[index].time = e.value;
                  setCurrentSteps(newSteps);
                }}
                placeholder="Time"
              />
              <Button text="Remove" onClick={() => handleRemoveStep(index)} />
            </div>
          ))}
          <Button
            text="Add Step"
            onClick={() => setCurrentSteps([...currentSteps, { id: '', time: '' }])}
          />
          <Button
            text="Save"
            onClick={handleStepsSave}
            style={{ marginTop: '10px' }}
          />
        </div>
      </Popup>
      <Popup
        visible={isPoseGroupTimesPopupVisible}
        onHiding={() => setIsPoseGroupTimesPopupVisible(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Edit Pose Group Times"
        width={300}
        height={400}
        style={modalStyle}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {Object.entries(currentPoseGroupTimes).map(([pose, time], index) => (
            <div key={index} style={{ marginBottom: '10px', width: '50%' }}>
              <TextBox
                value={pose}
                onValueChanged={(e: ValueChangedEvent) => {
                  const newPoseGroupTimes = { ...currentPoseGroupTimes };
                  const newTime = newPoseGroupTimes[pose];
                  delete newPoseGroupTimes[pose];
                  newPoseGroupTimes[e.value] = newTime;
                  setCurrentPoseGroupTimes(newPoseGroupTimes);
                }}
                placeholder="Pose"
              />
              <TextBox
                value={time}
                onValueChanged={(e: ValueChangedEvent) => {
                  const newPoseGroupTimes = { ...currentPoseGroupTimes };
                  newPoseGroupTimes[pose] = e.value;
                  setCurrentPoseGroupTimes(newPoseGroupTimes);
                }}
                placeholder="Time"
              />
              <Button text="Remove" onClick={() => handleRemovePoseGroupTime(pose)} />
            </div>
          ))}
          <Button
            text="Add Pose Group Time"
            onClick={() => setCurrentPoseGroupTimes({ ...currentPoseGroupTimes, '': '' })}
          />
          <Button
            text="Save"
            onClick={handlePoseGroupTimesSave}
            style={{ marginTop: '10px' }}
          />
        </div>
      </Popup>
      <Popup
        visible={isUploadModalVisible}
        onHiding={() => setIsUploadModalVisible(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Upload Content"
        width={500}
        height={400}
      >
        <ContentUploadModal sessionId={selectedSessionId} instructor={selectedInstructor} token={token} />
      </Popup>
      <style>{`
        .ReactVirtualized__Table__headerColumn {
          font-size: 14px;
          font-weight: 100;
          font-family: Lato;
          text-transform: capitalize;
          margin-right: 0px;
        }
      `}</style>
    </>
  );
};

export default HomeSection;
