/* eslint-disable no-void */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect } from 'react';

import { useUser } from '@youga/youga-client-api';

import API from '../services/API';
import useAuth from './useAuth';
import useTracking from './useTracking';

const useServiceWorkerMessages = (): void => {
  const { track } = useTracking();
  const auth = useAuth();
  const { data: user } = useUser();
  const isLoaded = !!user;

  /**
   * Listens to messages from service-worker to main-window
   *
   * If we got a push-subscription message, we will send it to the backend to store the endpoint information to the users tokens
   *
   * This effect depends on the loaded-state of the homeContents API to check if we have already stored this subscription
   */
  useEffect(() => {
    const onMessage = ({ data }: MessageEvent) => {
      switch (data.type) {
        case 'pushSubscription':
          // eslint-disable-next-line no-case-declarations
          const webTokens = user?.notificationDevices?.web || [];

          // if we have already stored the subscription token, we must not send it again
          if (webTokens.includes(data.payload)) {
            break;
          }

          if (auth.status !== 'authenticated') {
            // TODO: throw error?
            return;
          }

          API.postUserNotificationsToken(auth.token, data.payload, 'web');
          break;

        case 'push-notification-action':
          track(data.payload.action, data.payload.data);
          break;

        default:
          console.log('Got unhandled message from service-worker', data.type);
          break;
      }
    };

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', onMessage);
    }

    return (): void => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.removeEventListener('message', onMessage);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);
};

export default useServiceWorkerMessages;
